<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">监考老师设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="监考老师姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8em">监考老师姓名:</span>
              <el-input v-model="searchForm.teacherName" clearable size="small" placeholder="请输入监考老师姓名"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="searchForm.teacherMobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="selectTeacher()">选择监考老师</el-button>
              <el-button type="primary" class="bgc-bv" round @click="$router.back()">返回</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" show-overflow-tooltip prop="teacherName" />
              <el-table-column label="身份证号" align="center" show-overflow-tooltip prop="idCard" />
              <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
              <el-table-column label="角色类型" align="center" show-overflow-tooltip prop="roleName" />
              <el-table-column label="操作" align="center" show-overflow-tooltip>
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="delTeacher(scope.row.invigilationId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 选择监考老师 - 弹框 -->
    <el-dialog :title="dialogData.dialogTitle" :visible.sync="dialogData.dialogState" v-if="dialogData.dialogState" :close-on-click-modal="false" width="800px" @close="closeModel">
      <div class="operationControl">
        <div class="searchbox">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:3rem">姓名:</span>
            <el-input v-model="dialogData.teacherName" clearable size="small" placeholder="请输入姓名"></el-input>
          </div>
          <div class="df">
            <el-button type="primary" class="bgc-bv" round @click="dialogGetData()">搜索</el-button>
          </div>
        </div>
        <el-button type="primary" class="bgc-bv" round @click="dialogSubmit()">确认</el-button>
      </div>
      <el-table ref="multipleTable" :data="dialogData.tableData" row-key="teacherId" @selection-change="handleSelectionChange" height="500" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
        <el-table-column type="selection" align="center" reserve-selection />
        <el-table-column label="姓名" align="center" show-overflow-tooltip prop="teacherName" />
        <el-table-column label="身份证号" align="center" show-overflow-tooltip prop="idCard" />
        <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
        <!-- <el-table-column label="角色类型" align="center" show-overflow-tooltip prop="roleName" /> -->
        <Empty slot="empty" />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_invigilator",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data () {
    return {
      examinationId: '',
      searchForm: {
        teacherName: '',
        teacherMobile: '',
      },
      dialogData: {
        dialogTitle: '选择监考老师',
        dialogState: false,
        teacherName: '',
        tableData: [],
        multipleSelection: []
      },
    };
  },
  created () {
    this.examinationId = this.$route.query.examinationId
    this.getData()
  },
  mounted () {
  },
  methods: {
    getData (pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationId: this.examinationId
      };
      if (this.searchForm.teacherName) {
        params.teacherName = this.searchForm.teacherName;
      }
      if (this.searchForm.teacherMobile) {
        params.teacherMobile = this.searchForm.teacherMobile;
      }
      this.doFetch({
        url: "/biz/examination/invigilation/paper/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    delTeacher (invigilationId) {
      console.log(invigilationId);
      this.doDel({
        url: "/biz/examination/invigilation/paper/delete",
        msg: "确定删除该监考老师吗？",
        ps: {
          type: "post",
          data: { invigilationId },
        },
      }, true, 6);
    },
    // 弹窗
    closeModel () {
      this.dialogData = this.$options.data().dialogData
    },
    handleSelectionChange (val) {
      this.dialogData.multipleSelection = val;
    },
    async selectTeacher () {
      try {
        await this.dialogGetData()
      } catch (error) {
        console.log(error);
      }
      this.dialogData.dialogState = true
    },
    dialogGetData () {
      return this.$post('/biz/exam/teacher/list', {
        teacherRoleType:'20',
        teacherName: this.dialogData.teacherName
      }).then((res) => {
        this.dialogData.tableData = res.data
      })
    },
    // 新增/编辑 - 确认
    dialogSubmit () {
      const teacherIdList = this.dialogData.multipleSelection.map(e=>e.teacherId)
      const data = {
        examinationId: this.examinationId,
        teacherIdList
      }
      this.$post('/biz/examination/invigilation/paper/insert',data,3000,true,6)
      .then((res) => {
        if (res.status == 0) {
          this.closeModel()
          this.$message.success(res.message);
          this.getData(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    // 弹窗end
  },
};
</script>
<style lang="less" scoped>

</style>
